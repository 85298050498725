<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit {{title}}</b>
      <b v-else>Create {{title}}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Fiscal Year</label>
        <datepicker
          :disabled="id!=null"
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="FiscalYear"
          v-model="data.fiscal_year"
          placeholder="Select Fiscal Year"
          minimum-view="year" 
          :format="data.FicalYearFormat"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('FiscalYear')">{{ errors.first('FiscalYear') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Period</label>
        <datepicker
          :disabled="id!=null"
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Period"
          v-model="data.period"
          placeholder="Select Period"
          minimum-view="month" 
          :format="data.PeriodFormat"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('Period')">{{ errors.first('Period') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <br />
          <ul class="centerx">
            <li>
              <vs-radio v-model="data.status" vs-value="0"
                >Open</vs-radio
              >&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <vs-radio v-model="data.status" vs-value="1"
                >Close</vs-radio
              >
            </li>
          </ul>
        </div>
      </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>

import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker
  },
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    },
    title: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          period: "",
          fiscal_year: "",
          status: 0,
          FicalYearFormat: 'yyyy',
          PeriodFormat: 'MM',
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {

        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      const d = new Date(this.data.fiscal_year);
      let year = d.getFullYear();

      const e = new Date(this.data.period);
      let month = e.getMonth() + 1;
      
      return {        
        status: parseInt(this.data.status),
        fiscal_year: String(year),
        period: String(("0" + (month)).slice(-2))
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.fiscal_year = resp.data.fiscal_year;
            this.data.period = resp.data.period;
            this.data.status = resp.data.status;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    // setPaymentMethodId(val) {
    //   console.log(val, 'set')
    //   this.data.paymentMethodId = val;
    // }
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>
<style >
</style>
